<template>
  <div id="homePage">
    <div class="page-top">
      <img src="../assets/head.png" class="page-head" />
      <div class="page-tab">
        <div
          :class="[tab == i ? 'tab-box active-tab' : 'tab-box']"
          v-for="(item, i) in tabList"
          :key="i"
          @click="clickTab(i)"
        >
          <img src="../assets/tab-bg.png" class="tab-bg" />
          <div class="tab-text">{{ item }}</div>
        </div>
      </div>
    </div>
    <div class="page-inner">
      <div class="page-box" v-for="(item, i) in list" :key="i">
        <div class="box-inner">
          <swiper class="box-img" :options="swiperOption">
            <swiper-slide v-for="(slide, index) in item.slides" :key="index">
              <img :src="slide.image" />
            </swiper-slide>
          </swiper>
          <div class="box-info">
            <div class="box-line">
              <div class="box-label">产品名称：</div>
              {{ item.name }}
            </div>
            <div class="box-line">
              <div class="box-label">产品类型：</div>
              {{ item.type }}
            </div>
            <div class="box-line">
              <div class="box-label">系统简介：</div>
              {{ item.desc }}
            </div>
            <div class="box-line">
              <div class="box-label">展示案例：</div>
              {{ item.show }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-bot">
      <a href="https://beian.miit.gov.cn">Copyright&ensp;2024©&ensp;Guangdong&ensp;gusher.tech&ensp;Rights Reserved.粤ICP备18089087号&ensp;技术支持:&ensp;广州新水科技有限公司</a>
    </div>
  </div>
</template>

<script>
import { getProductAmountMonth } from "@/request/Api";

export default {
  name: "homePage",
  data() {
    return {
      tabList: [
        "企业官网",
        "微信小程序",
        "工业化软件",
        "定制化软件",
        "应用程序",
      ],
      tab: 0,
      list: [
        {
          name: "金平区“互联网+智慧监管”",
          type: "互联网+智慧监管系统",
          desc: "识别农贸市场“占道经营”,“违章停车”",
          show: "通过派单管理方式下达各站点负责人",
          slides: [  
            { image: require('../assets/jinpin/one.png') },  
            { image: require('../assets/jinpin/tow.png') },
            { image: require('../assets/jinpin/thress.png') } 
          ]
        },
        {
          name: "高新区“智慧监管平台”",
          type: "智能化+智慧监管平台系统",
          desc: "识别商户“口罩识别”,“厨师帽识别”,“抽烟识别”,“老鼠识别”等",
          show: "通过商户配餐区/洗料区/熟食区的监控进行视频转码、视频分析，识别事件通过派单管理方式下达各商户负责人",
          slides: [  
            { image: require('../assets/gaoxin/one.png') },  
            { image: require('../assets/gaoxin/tow.png') },
            { image: require('../assets/gaoxin/thress.png') } 
          ]
        },
        {
          name: "深澳海上监测平台",
          type: "应用软件+标签包装设备",
          desc: "物料分配-归纳-匹配-出库-包装",
          show: "ABB",
          slides: [  
            { image: require('../assets/nanao/one.png') },  
            { image: require('../assets/nanao/tow.png') },
            { image: require('../assets/nanao/thress.png') } 
          ]
        },
        {
          name: "养殖区智慧平台系统",
          type: "应用软件+标签包装设备",
          desc: "物料分配-归纳-匹配-出库-包装",
          show: "ABB",
          slides: [  
            { image: require('../assets/one.png') },  
            { image: require('../assets/one.png') },
            { image: require('../assets/one.png') } 
          ]
        },
      ],
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
        },
      },
    };
  },
  mounted() {},
  methods: {
    clickTab(i) {
      this.tab = i;
    },
  },
};
</script>
<style lang="less" scoped>
#homePage {
  width: 100vw;
  min-height: 100vh;
  overflow-x: hidden;
  background-color: #000327;
  background-image: url("../assets/bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  .page-top {
    .page-head {
      width: 100%;
    }
    .page-tab {
      display: flex;
      justify-content: center;
      position: relative;
      bottom: 24px;
      .tab-box {
        width: 238px;
        height: 52px;
        position: relative;
        z-index: 2;
        transition: 0.3s;
        background-color: #000327;
        border: 1px solid #114dd97f;
        box-shadow: 0px 0px 88px 7px rgba(16, 108, 222, 0.22) inset,
          0px 0px 11px 3px rgba(0, 114, 255, 0.4) inset;
        margin: 0 0.5px;
        cursor: pointer;
        user-select: none;
        .tab-bg {
          width: 100%;
          height: calc(100% + 3.6px);
          display: block;
          position: absolute;
          top: calc(50% + 0.6px);
          transform: translateY(-50%);
          opacity: 0;
        }
        .tab-text {
          line-height: 54px;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          text-align: center;
          color: #fff;
          font-size: 16px;
        }
      }
      .tab-box:hover,
      .active-tab {
        box-shadow: none;
        border-color: transparent;
        .tab-bg {
          opacity: 1;
        }
        .tab-text {
          background: linear-gradient(
            0deg,
            #03bcff 0.537109375%,
            #aafcff 98.6328125%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
  .page-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .page-box {
      width: 45vw;
      max-width: 743px;
      height: 19.43vw;
      max-height: 321px;
      position: relative;
      z-index: 2;
      background-image: url("../assets/box-bg.png");
      background-size: cover;
      background-repeat: no-repeat;
      margin: 18px 27px;
      .box-inner {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 50px;
        .box-img {
          width: 50%;
          height: 60%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .box-info {
          width: 47%;
          margin-left: 3%;
          .box-line {
            display: flex;
            color: #fff;
            font-size: 14px;
            margin: 10px 0;
            .box-label {
              text-wrap: nowrap;
              font-weight: bold;
              background: linear-gradient(
                0deg,
                #03bcff 0.537109375%,
                #aafcff 98.6328125%
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
        }
      }
    }
  }
  .page-bot {
    position: relative;
    z-index: 2;
    text-align: center;
    margin: 43px 0 29px;
    user-select: none;
    a {
      color: #fff;
      font-size: 14px;
      opacity: 0.5;
      text-decoration: none;
    }
  }
}
</style>
